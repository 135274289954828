@import '../../style/App.scss';

.not-found {
  padding-top: 150px;
  padding-bottom: 60px;
  text-align: center;
  height: 88vh;

  h1 {
    font-size: 80px;
  }

  p {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .btn {
    padding: 10px 20px;
    font-size: 18px;
  }
}

@media screen and (max-width: 1080px) {
  .not-found {
    padding-top: 140px;

    h1 {
      font-size: 70px;
    }

    p {
      font-size: 20px;
    }

    .btn {
      padding: 8px 18px;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 990px) {
  .not-found {
    padding-top: 120px;

    h1 {
      font-size: 60px;
    }

    p {
      font-size: 18px;
    }

    .btn {
      padding: 6px 16px;
      font-size: 14px;
    }
  }
}
