@import '../../style/App.scss';

.hero {
  padding-top: 150px;
  width: 100%;
  padding-bottom: 60px;
  height: auto; // Updated to accommodate varying content height
  
  .hero-info {
    padding-top: 60px;

    p {
      padding-top: 20px;
      padding-bottom: 50px;
    }
  }

  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); // 3 columns with larger images
    gap: 15px;
    margin-top: 20px;
  }

  .gallery-item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    height: 300px; // Increase the height for larger images
    .placeholder {
      width: 100%;
      height: 100%;
      background-color: #f0f0f0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease, opacity 0.5s ease;
      opacity: 0;
      &.fade-in {
        opacity: 1;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .weight800 {
    font-weight: 800;
  }
  
  .font60 {
    font-size: 60px;
  }
}

@media screen and (max-width: 1080px) {
  .hero {
    padding-top: 140px;

    .hero-info {
      padding-top: 0px;
    }

    .hero-image {
      float: none;
      margin: 0 auto;
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: 990px) {
  .hero {
    .hero-info {
      text-align: center;
    }
  }
}

.modal-content {
  background: transparent;
  border: none;
  box-shadow: none;
}

.modal-body-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.modal-body-custom img {
  max-width: 100%;
  max-height: 90vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.modal-backdrop.show{
  opacity: 0 !important;
}
